// Карточка пещеры

import React from "react";
import copy from "copy-to-clipboard";
import "react-lazy-load-image-component/src/effects/blur.css";

import {
  Modal,
  Input,
  List,
  Avatar,
  message,
  Button,
  Image,
  Space,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import {
  CommentOutlined,
  FileImageTwoTone,
  SendOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  ArrowDownOutlined,
  UserOutlined,
  LoadingOutlined,
  FilePdfOutlined,
  FilePdfFilled,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import Collapse from "rc-collapse";
import "rc-collapse/assets/index.css";
import { Panel } from "rc-collapse";
import { jsParseDate } from "../lib/func";
import { generateNewId, upd_date } from "../lib/apiReq";
import MapOneCave from "./MapOneCave";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Lightbox } from "react-modal-image";

const CaveCard = () => {
  const [spinner, setSpinner] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [upd_key, upd_setKey] = useState(0);
  const [showOneCaveMap, setShowOneCaveMap] = useState(false);
  const [openLightbox, setOpenLightBox] = useState(false);
  const [lightBoxSrc, setLightBoxSrc] = useState("null");

  // все фотки пещеры + одна новая фотка (в момент добавления)
  const [allCavePhotosWithNew, setAllCavePhotosWithNew] = useState("");

  // commentField state
  const [commentField, setCommentField] = useState(false);

  // commentText
  const [opinion, setOpinion] = useState("");

  // all comments to this card
  const [commentsToCave, setCommentsToCave] = useState([]);

  const [locationData, setLocationData] = useState("null"); //управляемая переменная координат
  const [historyData, setHistoryData] = useState("null"); //управляемая переменная описания
  const [researchData, setResearchData] = useState("null"); //управляемая переменная исследования

  const [editHistory, setEditHistory] = useState(false); // редактировать описание?
  const [editResearch, setEditResearch] = useState(false); // редактировать исследования?
  const [editLocation, setEditLocation] = useState(false); // редактировать координаты?

  const [commentImageUrl, setCommentImageUrl] = useState(""); //картинка закреплена к комменту

  // сравнение дат для сортировки
  function compare(a, b) {
    var dateA = new Date(a.date);
    var dateB = new Date(b.date);

    return dateB - dateA;
  }

  const contentStyle = {
    margin: 2,
    maxHeight: "300px",
    maxWidth: "300px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const arrowPath =
    "M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88" +
    ".5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3." +
    "6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-0.7 5." +
    "2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z";

  function expandIcon({ isActive }) {
    return (
      <i style={{ marginRight: ".5rem", float: "right" }}>
        <svg
          viewBox="0 0 1024 1024"
          width="1em"
          height="1em"
          fill="currentColor"
          style={{
            verticalAlign: "-.125em",
            transition: "transform .2s",
            transform: `rotate(${isActive ? 90 : 0}deg)`,
            float: "right",
          }}
        >
          <path d={arrowPath} p-id="5827" />
        </svg>
      </i>
    );
  }

  // фоточки для комментов
  const props = {
    name: "image",
    listType: "picture",
    maxCount: 1,
    action: "https://followmytrack.ru/upload_img.php",

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(
          "imgs->",
          info.fileList.map((el) => el.response.link).join()
        );
        if (info.fileList.length > 0)
          setCommentImageUrl(info.fileList[0].response.link);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  // const props = {
  //   name: "image",
  //   listType: "picture",
  //   maxCount: 1,
  //   action:
  //     "https://api.imgbb.com/1/upload?key=75dc7e6aa26326b5d8b2fc30689e8fa9",

  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //       if (info.fileList.length > 0)
  //         setCommentImageUrl(info.fileList[0].response.data.image.url);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} Фото загружен`);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} Ошибка загрузки`);
  //     }
  //   },
  // };

  // Доролнить карточку пещеры ОДНОЙ фотографией - настройки загрузчика

  // const propsImage = {
  //   name: "image",
  //   listType: "picture",
  //   maxCount: 1,
  //   action:
  //     "https://api.imgbb.com/1/upload?key=75dc7e6aa26326b5d8b2fc30689e8fa9",

  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //       console.log(cardData[0].image);
  //       if (info.fileList.length > 0) {
  //         editOneFieldById(
  //           "image",
  //           cardData[0].image + "," + info.fileList[0].response.data.image.url,
  //           cardData[0].id
  //         );
  //         readFromDb();
  //         upd_setKey(Math.random(1000));
  //       }
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} Фото загружено`);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} Ошибка загрузки`);
  //     }
  //   },
  // };

  const propsImage = {
    name: "image",
    listType: "picture",
    maxCount: 1,
    action: "https://followmytrack.ru/upload_img.php",

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(
          "imgs->",
          info.fileList.map((el) => el.response.link).join()
        );
        if (info.fileList.length > 0) {
          editOneFieldById(
            "image",
            cardData[0].image + "," + info.fileList[0].response.link,
            cardData[0].id
          );
          readFromDb();
          upd_setKey(Math.random(1000));
        }
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} фото загружено`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} ошибка загрузки`);
      }
    },
  };

  const propsTopos = {
    name: "image",
    listType: "picture",
    maxCount: 1,
    action: "https://followmytrack.ru/upload_img.php",

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(
          "imgs->",
          info.fileList.map((el) => el.response.link).join()
        );
        if (info.fileList.length > 0) {
          editOneFieldById(
            "topos",
            cardData[0].topos + "," + info.fileList[0].response.link,
            cardData[0].id
          );
          readFromDb();
          upd_setKey(Math.random(1000));
        }
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} топо загружено`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} ошибка загрузки`);
      }
    },
  };

  useEffect(() => {
    readFromDb();
    setCommentsToCave(
      readOpininsByCaveId(window.location.href.split("/").pop())
    );
  }, [upd_key]);

  // чтение всех записей из таблицы пещеры

  const readFromDb = async () => {
    setSpinner(true);
    try {
      const response = await fetch(
        `https://followmytrack.ru/caves.php?id=${window.location.href
          .split("/")
          .pop()}`
      );
      const result = await response.json();

      setCardData(result);
      setHistoryData(result[0].history);
      setLocationData(result[0].location);

      console.log("Cave Dowmloaded", result);
    } catch (error) {
      console.error(`Download error: ${error.message}`);
    }
    setSpinner(false);
  };

  // редактирование одного поля одной записи

  const editOneFieldById = async (field_name, field_data, id) => {
    setSpinner(true);

    console.log("input_params->>", field_name, field_data, id);

    const upd_date = new Date().toLocaleString("ru", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timezone: "UTC",
      hour: "numeric",
      minute: "numeric",
    });

    var formdata = new FormData();

    formdata.append("field_name", field_name);
    formdata.append("field_data", field_data);
    formdata.append("upd_date", upd_date);
    formdata.append("id", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://followmytrack.ru/caveEdit.php", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    setSpinner(false);
  };

  // Отправить комментарий

  const sendOpinion = async (data) => {
    console.log("opinion to send -> ", data);

    var formdata = new FormData();
    formdata.append("date", data.date);
    formdata.append("id", data.id);
    formdata.append("idUser", data.idUser);
    formdata.append("userName", data.userName);
    formdata.append("idCave", data.idCave);
    formdata.append("text", data.text);
    formdata.append("image", data.image);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://followmytrack.ru/opinionCave.php", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    upd_setKey(Math.random(100));
    //  window.location.reload(true);
  };

  //  Загрузка комментов к посту по ID

  const readOpininsByCaveId = async (caveId) => {
    try {
      const response = await fetch(
        `https://followmytrack.ru/opinionsByCave.php?id=${caveId}`
      );
      const result = await response.json();
      setCommentsToCave(result);
      return await result;
    } catch (error) {
      console.error(`Download error: ${error.message}`);
    }
  };

  const IconText = ({ icon, text, color }) => (
    <span>
      <font color={color}>{React.createElement(icon)}</font>
      <span> </span>
      {text}
    </span>
  );

  return (
    <div>
      {/* Лайтбокс фото */}
      {openLightbox && (
        <Lightbox
          style={{ paddingTop: "3px" }}
          onClose={() => setOpenLightBox(false)}
          medium={lightBoxSrc}
        />
      )}

      {cardData && (
        <Modal
          visible={showOneCaveMap}
          style={{ maxWidth: "950px" }}
          bodyStyle={{
            backgroundColor: "rgb(168, 174, 166)",
          }}
          footer={null}
          width="94%"
          onCancel={() => setShowOneCaveMap(false)}
        >
          <MapOneCave
            location={cardData[0].location}
            cave_name={cardData[0].cave_name}
          />
        </Modal>
      )}

      {cardData && (
        <List
          key={upd_key}
          className="alphaback"
          itemLayout="vertical"
          style={{ backgroundColor: "#dcdcdc" }}
          loading={spinner}
          size="large"
          dataSource={cardData}
          footer={
            <div style={{ paddingLeft: "10px" }}>
              <span style={{ paddingLeft: "4px" }} />
              Последнее обновление:
              <i>{cardData[0].upd_date} </i>
              <br />
              <span style={{ paddingLeft: "4px" }} />
              <img src="https://followmytrack.ru/fmt.png" width={"15pt"} />{" "}
              <b>FollowMyTrack: </b> Cave Card Module
            </div>
          }
          header={
            <div>
              <font size="4">
                <Space>
                  <span style={{ paddingLeft: "4px" }} />{" "}
                  {cardData[0].is_verified == 1 ? "✅ " : "❗ "}
                  <b>{cardData[0].cave_name} </b>
                </Space>
              </font>
              <br />
              <Space>
                <span style={{ paddingLeft: "4px" }} />{" "}
                <IconText
                  icon={CaretDownOutlined}
                  color="red"
                  text={cardData[0].deep}
                  key="list-vertical-star-o"
                />
                <IconText
                  icon={CaretRightOutlined}
                  color="green"
                  text={cardData[0].length}
                  key="list-vertical-like-o"
                />
                <IconText
                  icon={CaretUpOutlined}
                  text={cardData[0].alt_entrance}
                  key="list-vertical-message"
                />
              </Space>
            </div>
          }
          renderItem={(item) => (
            <List.Item
              key={item.id}
              actions={[]}
              extra={
                <img width={272} alt="logo" src={item.image.split(",")[0]} />
              }
            >
              <List.Item.Meta
                style={{ marginBottom: "0px;" }}
                avatar={<Avatar size="large" src={item.image.split(",")[0]} />}
                title={
                  <font size="2">
                    {!editLocation ? (
                      <span key={upd_key}>
                        {localStorage.getItem("userAttribute") == 1 ? (
                          <>
                            <a
                              onClick={() => {
                                copy(item.location);
                                message.success("Координаты скопированы");
                              }}
                            >
                              {item.location}
                            </a>
                            <span> | </span>
                            <a onClick={() => setShowOneCaveMap(true)}>
                              На карте
                            </a>
                          </>
                        ) : null}
                        <span> | </span>
                        {localStorage.getItem("userAttribute") == 1 ? (
                          <a
                            onClick={() => {
                              setEditLocation(true);
                            }}
                          >
                            ред.<font size="4">✍</font>
                          </a>
                        ) : null}
                      </span>
                    ) : (
                      <div>
                        <Input
                          defaultValue={item.location}
                          onChange={(e) => setLocationData(e.target.value)}
                        />
                        <br />
                        <Space>
                          <a
                            onClick={() => {
                              editOneFieldById(
                                "location",
                                locationData,
                                item.id
                              );
                              message.success("Сохранено");
                              window.location.reload();
                            }}
                          >
                            Сохранить
                          </a>
                          |
                          <a onClick={() => setEditLocation(false)}>Отменить</a>
                        </Space>
                      </div>
                    )}
                  </font>
                }
                description={item.cave_region}
              />

              <b>Описание пещеры: </b>

              {localStorage.getItem("userAttribute") == 1 ? (
                <a
                  onClick={() => {
                    setEditHistory(true);
                  }}
                >
                  ред.<font size="4">✍</font>
                </a>
              ) : null}
              <br />
              {!editHistory ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.history
                      .replace(new RegExp("\n", "g"), " <br/ > ")
                      .replace(
                        /\b(https?\:\/\/\S+)/gm,
                        '<a href="$1">[ссылка]</a>'
                      ),
                  }}
                ></p>
              ) : (
                <div>
                  <TextArea
                    rows={10}
                    defaultValue={item.history}
                    onChange={(e) => setHistoryData(e.target.value)}
                  />
                  <br />
                  <Space>
                    <a
                      onClick={() => {
                        editOneFieldById("history", historyData, item.id);
                        message.success("Сохранено");
                        window.location.reload();
                      }}
                    >
                      Сохранить
                    </a>
                    |<a onClick={() => setEditHistory(false)}>Отменить</a>
                  </Space>
                </div>
              )}

              <Collapse>
                <Panel
                  header={<b>Исследования</b>}
                  headerClass="my-header-class"
                  expandIcon={expandIcon}
                >
                  {localStorage.getItem("userAttribute") == 1 ? (
                    <a
                      onClick={() => {
                        setEditResearch(true);
                      }}
                    >
                      ред.<font size="4">✍</font>
                    </a>
                  ) : null}
                  <br />
                  {!editResearch ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.research
                          .replace(new RegExp("\n", "g"), " <br/ > ")
                          .replace(
                            /\b(https?\:\/\/\S+)/gm,
                            '<a href="$1">[ссылка]</a>'
                          ),
                      }}
                    ></p>
                  ) : (
                    <div>
                      <TextArea
                        rows={10}
                        defaultValue={item.research}
                        onChange={(e) => setResearchData(e.target.value)}
                      />
                      <br />
                      <Space>
                        <a
                          onClick={() => {
                            editOneFieldById("research", researchData, item.id);
                            message.success("Сохранено");
                            window.location.reload();
                          }}
                        >
                          Сохранить
                        </a>
                        |<a onClick={() => setEditResearch(false)}>Отменить</a>
                      </Space>
                    </div>
                  )}
                </Panel>

                <Panel
                  header={<b>Топосъемки</b>}
                  headerClass="my-header-class"
                  expandIcon={expandIcon}
                >
                  {item.topos.length > 3 ? (
                    <Image.PreviewGroup>
                      {item.topos.split(",").map((el) => (
                        <Space>
                          {el.match(/\.([^.]+)$|$/)[1] == "jpg" ||
                          el.match(/\.([^.]+)$|$/)[1] == "png" ? (
                            <Image
                              width={90}
                              height={90}
                              src={el}
                              style={{
                                margin: "3px",
                                padding: "3px",
                              }}
                              placeholder={<LoadingOutlined />}
                            />
                          ) : (
                            <FilePdfOutlined
                              onClick={() => window.open(el)}
                              style={{
                                fontSize: "90px",
                                cursor: "pointer",
                                verticalAlign: "0",
                              }}
                            />
                          )}
                        </Space>
                      ))}{" "}
                    </Image.PreviewGroup>
                  ) : (
                    <i>Отсутствуют</i>
                  )}
                  <br />
                  <Upload
                    {...propsTopos}
                    style={{ width: "80%" }}
                    key={upd_key}
                  >
                    <a>+топо</a>
                  </Upload>
                </Panel>

                <Panel
                  header={<b>Фотографии</b>}
                  headerClass="my-header-class"
                  expandIcon={expandIcon}
                >
                  {item.image.length > 3 ? (
                    <Image.PreviewGroup>
                      {item.image.split(",").map((el) => (
                        <>
                          <Space>
                            <Image
                              style={{
                                margin: "3px",
                                padding: "3px",
                              }}
                              placeholder={<LoadingOutlined />}
                              width={90}
                              effect="blur"
                              height={90}
                              src={el}
                              margin="3px"
                              padding="3px"
                            />
                          </Space>
                        </>
                      ))}{" "}
                    </Image.PreviewGroup>
                  ) : (
                    <i>Отсутствуют</i>
                  )}
                  <br />
                  <Upload
                    {...propsImage}
                    style={{ width: "80%" }}
                    key={upd_key}
                  >
                    <a>+фото</a>
                  </Upload>
                </Panel>
              </Collapse>
              <br />
              <b>Комментарии:</b>
              <br />
              {/* Поле для коммента */}
              {localStorage.getItem("user") ? (
                <div
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#F4F4F4",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <TextArea
                    key={upd_key}
                    onChange={(e) => {
                      setOpinion(e.target.value);
                    }}
                  />
                  <br />

                  <Upload {...props} style={{ width: "80%" }} key={upd_key}>
                    <a>+фото </a>
                  </Upload>
                  <Button
                    style={{ marginTop: "5px" }}
                    icon={<SendOutlined />}
                    onClick={async () => {
                      setCommentField(false);
                      const id = generateNewId();
                      const idUser = localStorage.getItem("userId");
                      const userName = localStorage.getItem("user");
                      const idCave = item.id;
                      const text = opinion;
                      const image = commentImageUrl;
                      const date = new Date().toLocaleString("ru", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        timezone: "UTC",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                      });
                      sendOpinion({
                        id,
                        idUser,
                        userName,
                        idCave,
                        text,
                        date,
                        image,
                      });
                      console.log(await readOpininsByCaveId(idCave));

                      // await readOpininsByPostId(el.id);
                    }}
                  >
                    Отправить
                  </Button>
                </div>
              ) : (
                <font color="red">Чтобы написать комментарий нужно войти</font>
              )}
              <br />
              {/* Список комментариев */}
              <a
                onClick={async () => {
                  console.log(await readOpininsByCaveId(item.id));
                }}
              >
                ⟳ Обновить комментарии
              </a>
              {commentsToCave.length > 0 && (
                <List
                  key={upd_key}
                  locale={{
                    emptyText: (
                      <span>
                        <CommentOutlined style={{ fontSize: "16px" }} /> <br />К
                        данной карточке ещё нет не одного комментария
                      </span>
                    ),
                  }}
                  dataSource={commentsToCave.sort(
                    (a, b) =>
                      jsParseDate(b.date).date - jsParseDate(a.date).date
                  )}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar size="large" icon={<UserOutlined />} />}
                        title={item.userName}
                        description={item.date}
                      />
                      {item.text}
                      <br />
                      {item.image && <Image src={item.image} width={110} />}
                    </List.Item>
                  )}
                />
              )}
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default CaveCard;
