// главная страница - лента новостей

import {
  PageHeader,
  Card,
  List,
  Avatar,
  Skeleton,
  Modal,
  Button,
  Form,
  Input,
  Divider,
  Space,
  Image,
  message,
  Upload,
  Carousel,
} from "antd";
import "react-lazy-load-image-component/src/effects/blur.css";
import Picker from "emoji-picker-react";
import { Lightbox } from "react-modal-image";

import { Tag } from "antd";
import {
  HeartOutlined,
  EditOutlined,
  SmileOutlined,
  SendOutlined,
  RotateRightOutlined,
  SwapOutlined,
  RotateLeftOutlined,
  ZoomOutOutlined,
  ZoomInOutlined,
  EllipsisOutlined,
  UploadOutlined,
  UserOutlined,
  CommentOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { generateNewId, upd_date } from "../lib/apiReq";
import { jsParseDate } from "../lib/func";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SkeletonImage from "antd/lib/skeleton/Image";

const MainPage = () => {
  const { Meta } = Card;

  // настройки стиля карусели

  const contentStyle = {
    maxWidth: "300px",
    margin: 0,
    height: "50%",
    color: "#fff",
    lineHeight: "50%",
    textAlign: "center",
    background: "#364d79",
  };

  // настройки загрузчика изображений

  const props = {
    name: "image",
    listType: "picture",
    maxCount: 4,
    action: "https://followmytrack.ru/upload_img.php",

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(
          "imgs->",
          info.fileList.map((el) => el.response.link).join()
        );
        if (info.fileList.length > 0)
          setImageUrl(info.fileList.map((el) => el.response.link).join());
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  // настройки загрузчика audio

  const propsAudio = {
    name: "filename",
    maxCount: 1,
    action: "https://followmytrack.ru/upload_file.php",

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        if (info.fileList.length > 0)
          setAudioUrl(info.fileList[0].response.link);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  // настройки загрузчика file

  const propsFile = {
    name: "filename",
    maxCount: 1,
    action: "https://followmytrack.ru/upload_file.php",

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        if (info.fileList.length > 0)
          setFileUrl(info.fileList[0].response.link);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const [modalAddPost, setModalAddPost] = useState(false);
  const [openLightbox, setOpenLightBox] = useState(false);
  const [lightBoxSrc, setLightBoxSrc] = useState("null");

  const [spinner, setSpinner] = useState(false);
  const [posts, setPosts] = useState([]);

  // emoji
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  //image_URL_uploaded
  const [imageUrl, setImageUrl] = useState("");

  //audio_URL_uploaded
  const [audioUrl, setAudioUrl] = useState("");

  //audio_URL_uploaded
  const [fileUrl, setFileUrl] = useState("");

  // commentField state
  const [commentField, setCommentField] = useState(false);

  //commentText
  const [opinion, setOpinion] = useState("");

  //commentTextForNowRender
  const [commentsToPost, setCommentsToPost] = useState({
    id: "",
    commentArray: [],
  });

  const phpURL = "https://followmytrack.ru/post.php";

  useEffect(() => {
    readFromDb();
  }, []);

  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  // Загрузка постов

  const readFromDb = async () => {
    setSpinner(true);
    try {
      const response = await fetch(phpURL);
      const result = await response.json();
      setPosts(result);
      console.log("Posts Dowmloaded", result);
    } catch (error) {
      console.error(`Download error: ${error.message}`);
    }
    setSpinner(false);
  };

  //  Загрузка комментов к посту по ID

  const readOpininsByPostId = async (postId) => {
    try {
      const response = await fetch(
        `https://followmytrack.ru/opinionsByPost.php?id=${postId}`
      );
      const result = await response.json();
      setCommentsToPost({ id: postId, commentArray: result });
      return await result;
    } catch (error) {
      console.error(`Download error: ${error.message}`);
    }
  };

  // Отправить комментарий

  const sendOpinion = async (data) => {
    console.log("opinion to send -> ", data);

    var formdata = new FormData();
    formdata.append("date", data.date);
    formdata.append("id", data.id);
    formdata.append("idUser", data.idUser);
    formdata.append("userName", data.userName);
    formdata.append("idPost", data.idPost);
    formdata.append("text", data.text);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://followmytrack.ru/opinion.php", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    //  window.location.reload(true);
  };

  //   Отправить пост

  const sendPost = async () => {
    setModalAddPost(false);
    setSpinner(true);

    const date = new Date().toLocaleString("ru", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timezone: "UTC",
      hour: "numeric",
      minute: "numeric",
    });

    const name = document.forms[0].elements[0].value;
    const tags = document.forms[0].elements[1].value;
    const text = document.forms[0].elements[2].value;
    const video = document.forms[0].elements[3].value;
    const audio = audioUrl;
    const image = imageUrl;
    const file = fileUrl;
    // Уникальный id поста

    const id =
      Math.random().toString(36).substring(2, 10) +
      Math.random().toString(36).substring(2, 10);

    console.log(name, text, image, audio, id);

    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("date", date);
    formdata.append("text", text);
    formdata.append("video", video);
    formdata.append("image", image);
    formdata.append("tags", tags);
    formdata.append("audio", audio);
    formdata.append("file", file);
    formdata.append("id", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://followmytrack.ru/post.php", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    setSpinner(false);

    window.location.reload(true);
  };

  return (
    <div className="alphaback">
      <PageHeader
        className="site-page-header"
        style={{ width: "100%", paddingBottom: "10px" }}
        extra={
          localStorage.getItem("userAttribute") == 1 ? (
            <a
              onClick={() => {
                setModalAddPost(true);
              }}
            >
              <b>+ публикация</b>
            </a>
          ) : null
        }
        title="Новости сообщества"
      />
      {/* Лайтбокс галлереи */}
      {openLightbox && (
        <Lightbox
          style={{ paddingTop: "3px" }}
          onClose={() => setOpenLightBox(false)}
          medium={lightBoxSrc}
        />
      )}

      {/* Модалка для создания нового поста */}

      <Modal
        centered
        visible={modalAddPost}
        onCancel={() => setModalAddPost(false)}
        footer={null}
      >
        <br />
        <Card title="✍ Новая публикация на стене ">
          <Form name="nest-messages" onFinish={sendPost}>
            <b>
              <Form.Item name={["user", "name"]} label="Название">
                <Input />
              </Form.Item>
            </b>
            <br />
            <Form.Item name={["user", "tags"]} label="Категория">
              <Input />
            </Form.Item>
            <br />
            <Form.Item name={["user", "text"]} label="Сообщение">
              {showPicker && (
                <Picker
                  width={300}
                  skinTonesDisabled={true}
                  searchDisabled={true}
                  pickerStyle={{ width: "100%" }}
                  onEmojiClick={(event, emojiObject) => {
                    setInputStr((prevInput) => prevInput + event.emoji);
                    setShowPicker(false);
                  }}
                />
              )}
              <Space align="start">
                <Input.TextArea
                  showCount
                  maxLength={2000}
                  style={{ height: 120, marginBottom: 24 }}
                  value={inputStr}
                  onChange={(e) => setInputStr(e.target.value)}
                />
                <div></div>
                <SmileOutlined
                  style={{ fontSize: "large", color: "red" }}
                  onClick={() => setShowPicker((val) => !val)}
                />
              </Space>
            </Form.Item>

            <Divider orientation="left"> 🧩 Вложения </Divider>

            <Form.Item name={["user", "video"]} label="YouTube">
              <Input />
            </Form.Item>
            <br />
            <Form.Item name={["user", "audio"]} label="Музыка">
              <Upload {...propsAudio}>
                <Button icon={<UploadOutlined />}>Загрузить</Button>
              </Upload>
            </Form.Item>
            <br />
            <Form.Item name={["user", "photo"]} label="Фотографии">
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>Загрузить</Button>
              </Upload>
            </Form.Item>
            <br />
            <Form.Item name={["user", "file"]} label="Документы">
              <Upload {...propsFile}>
                <Button icon={<UploadOutlined />}>Загрузить</Button>
              </Upload>
            </Form.Item>
            <br />

            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button icon={<SendOutlined />} type="primary" htmlType="submit">
                Отправить
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
      {spinner ? (
        <div style={{ padding: "4px" }}>
          <Skeleton style={{ margin: "4px" }} active />;
        </div>
      ) : (
        <>
          {posts &&
            posts
              .slice(0)
              .sort(
                (a, b) => jsParseDate(b.date).date - jsParseDate(a.date).date
              )
              .map((el) => (
                <>
                  <Card
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      justifyContent: "center",
                      borderRadius: "10px",
                    }}
                    title={
                      <Meta
                        title={
                          <a href={`https://followmytrack.ru/posts/${el.id}`}>
                            {el.name}
                          </a>
                        }
                        style={{
                          "font-size": "small",
                        }}
                        description={
                          <>
                            {el.tags ? <Tag color="red">{el.tags}</Tag> : null}
                            {el.date}
                            {el.audio ? (
                              <>
                                <br />
                                <br />
                                <audio controls src={el.audio}></audio>
                              </>
                            ) : null}
                            {el.file ? (
                              <>
                                <br />
                                <span> Документ: </span>{" "}
                                <a href={el.file}>
                                  {" "}
                                  {el.file.replace(
                                    /(^.*[\\\/]+)|(_[^_]+$)/g,
                                    ""
                                  )}
                                </a>
                              </>
                            ) : null}
                          </>
                        }
                      />
                    }
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: el.text
                          .replace(new RegExp("\r?\n", "g"), " <br/ > ")
                          .replace(
                            /\b(https?\:\/\/\S+)/gm,
                            '<a href="$1">[ссылка]</a>'
                          ),
                      }}
                    ></p>

                    {el.image ? (
                      <>
                        <Divider />
                        <br />

                        <Carousel
                          adaptiveHeight={true}
                          autoplay
                          arrows
                          infinite={true}
                          style={{
                            maxWidth: "300px;",
                            display: "flex",
                          }}
                        >
                          {el.image.split(",").map((item) => (
                            <LazyLoadImage
                              onClick={() => {
                                setOpenLightBox(true);
                                setLightBoxSrc(item);
                              }}
                              effect="blur"
                              style={{
                                maxWidth: "300px;",
                                display: "flex",
                              }}
                              src={item}
                              placeholderSrc={<LoadingOutlined />}
                              placeholder={<LoadingOutlined />}
                            />
                          ))}
                        </Carousel>
                      </>
                    ) : null}
                    <br />

                    {el.video ? (
                      <>
                        <iframe
                          // class="iframe-placeholder"
                          width="100%"
                          height="300"
                          src={`https://www.youtube.com/embed/${youtube_parser(
                            el.video
                          )}`}
                          title="FollowMyTrack"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>
                      </>
                    ) : null}

                    {/* Поле для коммента */}

                    {localStorage.getItem("user") ? (
                      <div
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#F4F4F4",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <TextArea
                          onChange={(e) => {
                            setOpinion(e.target.value);
                          }}
                        />
                        <br />

                        <Button
                          style={{ marginTop: "5px" }}
                          icon={<SendOutlined />}
                          onClick={async () => {
                            setCommentField(false);
                            const id = generateNewId();
                            const idUser = localStorage.getItem("userId");
                            const userName = localStorage.getItem("user");
                            const idPost = el.id;
                            const text = opinion;
                            const date = new Date().toLocaleString("ru", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              timezone: "UTC",
                              hour: "numeric",
                              minute: "numeric",
                              second: "numeric",
                            });
                            sendOpinion({
                              id,
                              idUser,
                              userName,
                              idPost,
                              text,
                              date,
                            });
                            await readOpininsByPostId(el.id);

                            await readOpininsByPostId(el.id);
                          }}
                        >
                          Отправить
                        </Button>
                      </div>
                    ) : (
                      <font color="red">
                        Чтобы написать комментарий нужно войти
                      </font>
                    )}
                    <br />
                    {/* Список комментариев */}
                    <a
                      onClick={async () => {
                        console.log(await readOpininsByPostId(el.id));
                      }}
                    >
                      ⟳ Обновить комментарии
                    </a>
                    {commentsToPost.id == el.id && (
                      <List
                        key={generateNewId()}
                        locale={{
                          emptyText: (
                            <span>
                              <CommentOutlined style={{ fontSize: "16px" }} />{" "}
                              <br />К данной записи ещё нет не одного
                              комментария
                            </span>
                          ),
                        }}
                        dataSource={commentsToPost.commentArray}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <Avatar size="large" icon={<UserOutlined />} />
                              }
                              title={item.userName}
                              description={item.date}
                            />
                            {item.text}
                          </List.Item>
                        )}
                      />
                    )}
                  </Card>
                  <br />
                </>
              ))}
        </>
      )}

      <p style={{ padding: "0 15px" }}>
        Данный ресурс является проектом cообщества FollowMyTrack
      </p>
      <p style={{ padding: "0 15px" }}>
        Мы - исследователи пещер, путешественники и увлеченные люди. Мы собрали
        в едином каталоге данные по подземным полостям горного Крыма. Тут можно
        найти координаты пещер и посмотреть их на карте. По нашему кадастру
        можно производить поиск и фильтрацию пещер по заданным критериям.
      </p>
      <div style={{ padding: "0 15px" }}>
        Мы пополняем данные находя новые пещеры и новые ходы существующих пещер
        Приглашаем к участию в наших проектах. Связаться с нами можно в форме
        обратной связи или в сообществе{" "}
        <a href="http://vk.com/followmytrack">FollowMyTrack </a> в VK.
        <br />
      </div>
      <br />
    </div>
  );
};

export default MainPage;
