import { useEffect, useState } from "react";
import { Avatar, Image, Space } from "antd";
import { MailFilled } from "@ant-design/icons";
import { getUserById } from "../lib/apiReq";
import { LazyLoadImage } from "react-lazy-load-image-component";

const UserProfile = (id) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(async () => {
    const result = await getUserById(id.userId);
    console.log("----->>>>>>>", result[0]);
    setCurrentUser(result[0]);
    localStorage.setItem("userPhoto", result[0].photo);
  }, []);

  return (
    <div>
      {currentUser && (
        <>
          <Image src={currentUser.photo} />
          <br />
          <span>
            <Space>
              <Avatar size="small" icon={<MailFilled />} />
              <b>{currentUser.email}</b>
            </Space>
          </span>
          <br />
          <br />
          <span>
            <Space>
              <Avatar
                size="small"
                src={
                  <img
                    src={"https://vk.com/images/icons/favicons/fav_logo_2x.ico"}
                    alt="avatar"
                  />
                }
              />
              <b>
                <a href={currentUser.vk}>{currentUser.vk.split("/").pop()}</a>{" "}
              </b>
            </Space>
          </span>
        </>
      )}
    </div>
  );
};

export default UserProfile;
